import React, { ChangeEvent } from "react";
import { ExerciseData } from "./WorkoutData";

interface ExerciseProps {
  exercise: ExerciseData;
  showDetail: boolean;
}

const handleChange = (
  event: ChangeEvent<HTMLInputElement>,
  exercise: ExerciseData,
  isWarmup: boolean
) => {
  const numericValue = parseFloat(event.target.value);
  setValueForExercise(exercise, numericValue, isWarmup);
};

const localStorageKey = "exerciseValues";
const warmUpExerciseKey = "warmUp";
const workExerciseKey = "work";
export function Exercise({ exercise, showDetail }: ExerciseProps) {
  if (!showDetail) {
    return <div>{exercise.name}</div>;
  }

  return (
    <>
      <div className="ui medium header">{exercise.name}</div>
      <div className="ui tiny header" style={{ minHeight: "80px" }}>
        {exercise.description}
      </div>
      <div style={{ overflow: "hidden" }}>
        {prepareInput(exercise, true, "Warmup")}
        {prepareInput(exercise, false, "Pracovní")}
      </div>
    </>
  );
}

function getValueForExercise(exercise: ExerciseData, isWarmup: boolean) {
  const exerciseDataObject = getLocalStorageObject()[exercise.name] || {};
  const objectKey = isWarmup ? warmUpExerciseKey : workExerciseKey;
  return exerciseDataObject[objectKey] || 0;
}

function setValueForExercise(
  exercise: ExerciseData,
  value: number,
  isWarmup: boolean
) {
  const dataObject = getLocalStorageObject();
  const exerciseDataObject = dataObject[exercise.name] || {};
  const objectKey = isWarmup ? warmUpExerciseKey : workExerciseKey;
  exerciseDataObject[objectKey] = value;
  dataObject[exercise.name] = exerciseDataObject;
  localStorage.setItem(localStorageKey, JSON.stringify(dataObject));
}

function getLocalStorageObject() {
  const localStorageData = localStorage.getItem(localStorageKey) ?? "{}";
  return JSON.parse(localStorageData);
}

function prepareInput(exercise: ExerciseData, isWarmup: boolean, text: string) {
  return (
    <div style={{ paddingTop: "2px" }} className="ui massive labeled input">
      <div className="ui label">{text}</div>
      <input
        type={"number"}
        min={0}
        defaultValue={getValueForExercise(exercise, isWarmup)}
        onChange={(event) => handleChange(event, exercise, isWarmup)}
        key={`${exercise.name}${isWarmup}`}
      />
    </div>
  );
}
