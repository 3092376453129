import React, { ChangeEvent } from "react";

type Size = { name: string; proximityWeight: number };
type SizePrice = {
  size: Size;
  pricePerGram: number;
  price: number;
};

const sizes = [
  { name: "S", proximityWeight: 48 },
  { name: "M", proximityWeight: 58 },
  { name: "L", proximityWeight: 68 },
  { name: "XL", proximityWeight: 78 },
];

export default function EggPriceCalculator() {
  const [prices, setPrices] = React.useState<SizePrice[]>([]);

  const headers = sizes.map((size) => {
    return <th key={size.name}>{size.name}</th>;
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>, size: Size) => {
    const floatValue = parseFloat(event.target.value);
    let isNewSize = true;
    const updatedPrices: SizePrice[] = [];
    prices.forEach((price) => {
      if (price.size === size) {
        isNewSize = false;
        updatedPrices.push({
          size: size,
          pricePerGram: floatValue / size.proximityWeight,
          price: floatValue,
        });
      } else {
        updatedPrices.push(price);
      }
    });
    if (isNewSize) {
      updatedPrices.push({
        size: size,
        pricePerGram: floatValue / size.proximityWeight,
        price: floatValue,
      });
    }
    setPrices(updatedPrices);
  };

  return (
    <table>
      <thead>
        <tr>{headers}</tr>
      </thead>
      <tbody>
        <tr>{prepareInputs()}</tr>
      </tbody>
    </table>
  );

  function prepareInputs() {
    let bestValueSize: SizePrice | null = null;
    prices.forEach((price) => {
      if (
        bestValueSize === null ||
        bestValueSize.pricePerGram > price.pricePerGram
      ) {
        bestValueSize = price;
      }
    });
    return sizes.map((size) => {
      return (
        <td key={size.name}>
          <input
            style={{
              backgroundColor: size === bestValueSize?.size ? "lightgreen" : "",
            }}
            type={"number"}
            min={0}
            defaultValue={prices.find((price) => price.size === size)?.price}
            onChange={(event) => handleChange(event, size)}
            key={size.name}
          />
        </td>
      );
    });
  }
}
