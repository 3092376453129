export interface Workday {
  bodyParts: BodyPartData[];
}
export interface ExerciseData {
  name: string;
  description?: string;
  video?: string;
}

export interface BodyPartData {
  name: string;
  mustHaveExercises: ExerciseData[];
  optionalExercises: ExerciseData[];
}

const breastWorkout: BodyPartData = {
  name: "Prsa",
  mustHaveExercises: [
    {
      name: "Tlaky na šikmé lavici",
      description:
        "Úhel lavice ideálně cca 35 - 40 °, většinou druhá zarážka nad rovnou lavicí, úhel rukou od těla cca 45 - 50 °",
    },
    {
      name: "Rozpažky na šikmé lavici",
      description:
        "Úhel lavice ideálně cca 30 - 35 °, většinou první zarážka nad rovnou lavicí, úhel rukou od těla cca 45 - 50 °",
    },
    {
      name: "Pec dec",
      description: "Lopatky zaražené do opěradla, být pořádně narovnaný",
    },
  ],
  optionalExercises: [
    {
      name: "Stahování na protilehlých kladkách",
    },
  ],
};

const tricepsWorkout: BodyPartData = {
  name: "Triceps",
  mustHaveExercises: [
    {
      name: "Stahování rovné kladky",
      description:
        "Ideální je se do toho opřít jako by ta osa byla zeď na kterou chce člověk vylézt",
    },
    {
      name: "Dipy",
    },
    {
      name: "Skull crushers",
      description:
        "Může se jet celý rozsah, tj. 90 ° nebo jen zkrácený cca těch nějnáročnějších 45 °",
    },
  ],
  optionalExercises: [
    {
      name: "Obrácené kliky na triceps",
      description:
        "Ideálně mít ruce i tělo podložené, ruce trochu výš než nohy a kotouč si položit na břicho aby tlačil do nohou",
    },
    {
      name: "Diamantové kliky",
      description:
        "Ruce mít před hlavou aby se dotýkaly palci aukazováčky, které tvoří tvar diamantu",
    },
  ],
};

const bicepsWorkout: BodyPartData = {
  name: "Biceps",
  mustHaveExercises: [
    {
      name: "Zvih s jednoručkami",
      description:
        "Sedím na lavici bez opírání, nohy mám úplně u sebe, jednoručky zvedám s protočením vniřkem k prsu a dole nepouštím až dolů, ale rozumnně držím. Místo pracovní váhy se dá vzít např. o jednu větší a o jednu menší a nejprve jet s tou větší a poté s menší, oboje do vyčerpání",
    },
    {
      name: "Kladivový zvih s jednoručkami",
      description:
        "Můžeš být trošku shrbený a činky zvedat jen dolních 45 °, tj. kolmo k tělu a ruce nemusí být podél, ale můžou směřovat ke středu těla",
    },
    {
      name: "Bicepsový zdvih s velkou osou",
    },
    {
      name: "Pumpování",
      description:
        "V každé ruce mám dlaní nahoru/mírně dovnitř kotouč a střídám plný rozsah s pulsováním okolo 45 °",
    },
  ],
  optionalExercises: [],
};

const shoulderWorkout: BodyPartData = {
  name: "Ramena",
  mustHaveExercises: [
    {
      name: "Rozpažování",
      description:
        "Rozpažuji mírně ohnuté ruce, dole by se měly setkat zhruba u rozkroku. po poslední sérii ještě beru zahřívací váhu a vždy rozpažím na cca 20 vteřin a následně stáhnu ruce dolu a hned zase rozpažuji dokud můžu",
    },
    {
      name: "Delty na pec decku",
      description: "Stáhnout lopatky a hlídat si trapézy",
    },
    {
      name: "Rozpažování na šikmé lavici",
      description:
        "Lavici nastavím na 45 °, opřu se prsami o vrch lavice a rozpažuji, po poslední serii opět držím zahřící váhu",
    },
  ],
  optionalExercises: [
    {
      name: "Rotování kotoučem kolem hlavy",
      description:
        "V obou rukách držím kotouč a snažím se ho obtočit kolem hlavy, ale místo toho abych jel za hlavou tak jedu na hlavou",
    },
    {
      name: "Zvedání osy k prsoum",
    },
  ],
};

const backWorkout: BodyPartData = {
  name: "Záda",
  mustHaveExercises: [
    {
      name: "Shyby",
    },
    {
      name: "Přítahy spodní - ruce jednotlivě",
    },
    {
      name: "Přítahy horni obouruč",
    },
  ],
  optionalExercises: [
    {
      name: "Překlánění",
    },
    {
      name: "Přitahování horní kladky na úzko",
      description:
        "Nahoru se za kladkou úplně vytáhnout, dolů přitáhnout skoro k prsům",
    },
  ],
};

const legWorkout: BodyPartData = {
  name: "Nohy",
  mustHaveExercises: [
    {
      name: "Leg press",
      description:
        "Alternativou ke klasickému leg pressu může být leg press jednotlivých nohou",
    },
    {
      name: "Výpady",
    },
    {
      name: "Předkopávání",
    },
    {
      name: "Zakopávání",
    },
    {
      name: "Stroj na lýtka",
    },
  ],
  optionalExercises: [],
};

const sixPackWorkout: BodyPartData = {
  name: "Břicho",
  mustHaveExercises: [
    {
      name: "Zdvihy nohou ve visu",
      description:
        "Celý spevněný, ideálně špičky k rukám, ale jde hlavně o zvednutí pánnve k břichu",
    },
    {
      name: "Zkracovačky",
      description:
        "Nohy v pánvy kolmo k zemi v kolenou vodorovně se zemí, s nádechem jdeš horní částí trupu ke kolenoum a ideálně v kontrakci třeba vteřinu zůstat",
    },
    {
      name: "Plank",
      description:
        "Ideálně nezatínat nějak brutálně zadek a nespojovat ruce. Koukat do země. Alternativou může být posouvaní záteže na zemi nebo jen trochu pokrčit nohy.",
    },
    {
      name: "Stroj",
    },
  ],
  optionalExercises: [],
};

export const workoutData: Workday[] = [
  {
    bodyParts: [breastWorkout, tricepsWorkout],
  },
  {
    bodyParts: [shoulderWorkout, legWorkout],
  },
  {
    bodyParts: [bicepsWorkout, backWorkout],
  },
  {
    bodyParts: [sixPackWorkout],
  },
];

export function getWorkdayName(workday: Workday) {
  return workday.bodyParts.map((bodyPart) => bodyPart.name).join(", ");
}
