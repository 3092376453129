import React, { ReactNode } from "react";
import { Link, Outlet } from "react-router-dom";

const Menu = ({ subMenu }: { subMenu?: ReactNode }) => {
  return (
    <>
      <Link to="">
        <h1>💪💪💪</h1>
      </Link>
      <div>
        <Link to="/workout">Workout</Link>
        <Link to="/tools">Tools</Link>
      </div>
      {subMenu}
      <Outlet />
    </>
  );
};

export default Menu;
