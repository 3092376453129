import React from "react";
import { Link } from "react-router-dom";

export function Menu() {
  return (
    <>
      <div>
        <Link to="/tools/egg">Kalkulačka ceny vajec</Link>
        <Link to="/tools/quark">Nutriční hodnoty tvarohu</Link>
      </div>
    </>
  );
}
