import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Menu from "./Menu";
import Workouts from "./workouts/Workouts";
import { Menu as ToolsMenu } from "./tools/Menu";
import EggPriceCalculator from "./tools/EggPriceCalculator";
import QuarkNutritionValues from "./tools/QuarkNutritionValues";

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Menu />}>
          <Route path="workout" element={<Workouts />} />
          <Route path="tools" element={<ToolsMenu />} />
          <Route path="*" element={<></>} />
        </Route>
        <Route path="/tools" element={<Menu subMenu={<ToolsMenu />} />}>
          <Route path="egg" element={<EggPriceCalculator />} />
          <Route path="quark" element={<QuarkNutritionValues />} />
          <Route path="*" element={<></>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
