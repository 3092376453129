import React from "react";
import Router from "./components/Router";

export default function App() {
  return (
    <div style={{ margin: "10px" }}>
      <Router />
    </div>
  );
}
