import React, { useState } from "react";
import { BodyPart } from "./BodyPart";
import { getWorkdayName, Workday } from "./WorkoutData";
import { Exercise } from "./Exercise";

interface WorkdayProps {
  workday: Workday;
}

export default function Workout({ workday }: WorkdayProps) {
  const workoutName = getWorkdayName(workday);
  const bodyParts = workday.bodyParts.map((bodyPart) => (
    <BodyPart bodyPart={bodyPart} key={bodyPart.name} />
  ));
  const [currentExerciseId, setExerciseId] = useState<number>(-1);
  const exercises = workday.bodyParts
    .map((bodyPart) =>
      bodyPart.mustHaveExercises.concat(bodyPart.optionalExercises)
    )
    .flat(1);

  if (currentExerciseId > -1) {
    const exercise = exercises[currentExerciseId];

    const nextButton = prepareButton(
      exercises.length > currentExerciseId + 1,
      () => setExerciseId(currentExerciseId + 1),
      "Další",
      true
    );

    const previousButton = prepareButton(
      currentExerciseId > 0,
      () => setExerciseId(currentExerciseId - 1),
      "Předchozí",
      false
    );

    return (
      <>
        <div className="ui large header">
          {getBodyPartName(workday, currentExerciseId)}
        </div>
        <Exercise exercise={exercise} showDetail={true} />
        <div style={{ paddingTop: "10px" }}>
          {previousButton}
          {nextButton}
        </div>
      </>
    );
  }

  return (
    <>
      <h1>{workoutName}</h1>
      <div onClick={() => setExerciseId(0)}>Start</div>
      {bodyParts}
    </>
  );
}

function prepareButton(
  condition: boolean,
  buttonCallback: () => void,
  buttonText: string,
  isNext: boolean
) {
  const buttonClass =
    (isNext ? "right" : "left") + " floated" + (condition ? "" : " disabled");
  return (
    <button onClick={buttonCallback} className={`ui button ${buttonClass}`}>
      {buttonText}
    </button>
  );
}

function getBodyPartName(workday: Workday, currentExerciseId: number) {
  let alreadyPassedExercises = 0;
  let bodyPartName = "";

  workday.bodyParts.forEach((bodyPart) => {
    if (
      bodyPartName === "" &&
      currentExerciseId - alreadyPassedExercises + 1 <=
        bodyPart.mustHaveExercises.length + bodyPart.optionalExercises.length
    ) {
      bodyPartName = bodyPart.name;
    } else {
      alreadyPassedExercises +=
        bodyPart.mustHaveExercises.length + bodyPart.optionalExercises.length;
    }
  });

  return bodyPartName;
}
