import React from "react";
import { Exercise } from "./Exercise";
import { BodyPartData } from "./WorkoutData";

interface BodyPartProps {
  bodyPart: BodyPartData;
}

export function BodyPart({ bodyPart }: BodyPartProps) {
  const mustHaveExercises = bodyPart.mustHaveExercises.map((exercise) => (
    <Exercise exercise={exercise} showDetail={false} key={exercise.name} />
  ));
  const optionalExercises = bodyPart.optionalExercises.map((exercise) => (
    <Exercise exercise={exercise} showDetail={false} key={exercise.name} />
  ));
  return (
    <>
      <h2>{bodyPart.name}</h2>
      {mustHaveExercises}
      {optionalExercises}
    </>
  );
}
