import React, { useState } from "react";
import { workoutData, getWorkdayName, Workday } from "./WorkoutData";
import Workout from "./Workout";

export default function Workouts() {
  const [workoutDay, setWorkoutDay] = useState<Workday | null>(null);
  if (workoutDay) {
    return <Workout workday={workoutDay} />;
  }
  const workoutDays = workoutData.map((workoutDay) => (
    <div
      className="ui large header"
      onClick={() => setWorkoutDay(workoutDay)}
      key={getWorkdayName(workoutDay)}
    >
      {getWorkdayName(workoutDay)}
    </div>
  ));
  return <div>{workoutDays}</div>;
}
